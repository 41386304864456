import React, { useState } from 'react';
import { PlayerData } from '../../entities/PlayerData';
import { GameData } from '../../entities/GameData';

import { AxiosResponse } from 'axios';

import { Typography, ThemeProvider } from '@mui/material';
import {
  StyledContainer,
  StyledButton,
  StyledForm,
  ButtonContainer,
  StyledTextField,
} from '../../components/Styled/Styles';
import { Theme } from '../../components/Theme/ColorTheme';
import { HttpMethod } from '../../entities/RequestParams';
import { requestWithRetry } from '../../utils/ApiUtils';
import { getTsBuildInfoEmitOutputFilePath } from 'typescript';

function Matching({
  playerData,
  goToWaiting,
  goToTitle,
}: {
  playerData: PlayerData;
  goToWaiting: (data: GameData) => void;
  goToTitle: () => void;
}) {
  const [matchingPassword, setMatchingPassword] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/games`,
      onError: function (error: unknown, retryCount: number) {
        console.error(`Error creating game (retry: ${retryCount}):${error}`);
      },
      data: {
        game: {
          player_id: playerData.id,
          password: matchingPassword,
        },
      },
      onSuccess: function (response: AxiosResponse) {
        const gameData: GameData = {
          id: response.data['id'],
          password: response.data['password'],
          player: playerData,
        };
        goToWaiting(gameData);
      },
    });
  };

  const handleBack = async () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.DELETE,
      path: `/players/${playerData.id}`,
      onError: function (error: unknown, retryCount: number) {
        console.error(`Error deleting player (retry: ${retryCount}):${error}`);
        goToTitle();
      },
      onSuccess: function (_: AxiosResponse) {
        goToTitle();
      },
    });
  };

  return (
    <ThemeProvider theme={Theme}>
      <StyledContainer>
        <Typography variant="h5" component="h1" gutterBottom>
          同じ合言葉を入力した人とマッチします
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <StyledTextField
            value={matchingPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMatchingPassword(e.target.value)
            }
            fullWidth
            placeholder="合言葉を入力してください"
            sx={{
              backgroundColor: '#fff', // 背景色を白に設定
            }}
          />
          <ButtonContainer>
            <StyledButton variant="contained" onClick={handleBack}>
              戻る
            </StyledButton>
            <StyledButton variant="contained" type="submit">
              次へ
            </StyledButton>
          </ButtonContainer>
        </StyledForm>
      </StyledContainer>
    </ThemeProvider>
  );
}

export default Matching;
