import { AxiosResponse } from 'axios';

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export interface RequestParams {
  retryCount?: number;
  waitResponse?: boolean;
  maxRetryCount: number;
  method: HttpMethod;
  path: string;
  onError: (error: unknown, retryCount: number) => void;
  data?: any;
  onSuccess?: (response: AxiosResponse) => void;
}
