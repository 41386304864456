import React, { useState } from 'react';
import Title from '../Title/Title';
import Matching from '../Matching/Matching';
import Waiting from '../Waiting/Waiting';
import { PlayerData } from '../../entities/PlayerData';
import { GameData } from '../../entities/GameData';
import BattleField from '../BattleField/BattleField';
import Interval from '../Interval/Interval';
import { Box, Stack } from '@mui/material';
import VolumeControl from '../../components/VolumeControl/VolumeControl';
import styles from './Main.module.css';

enum ScreenType {
  TITLE = 'title',
  MATCHING = 'matching',
  WAITING = 'waiting',
  BATTLEFIELD = 'battlefield',
  INTERVAL = 'interval',
}

function Main() {
  const [screen, setScreen] = useState(ScreenType.TITLE);
  const [playerData, setPlayerData] = useState<PlayerData | null>(null);
  const [gameData, setGameData] = useState<GameData | null>(null);

  const goToMatching = (data: PlayerData) => {
    setPlayerData(data);
    setScreen(ScreenType.MATCHING);
  };

  const goToTitle = () => {
    setGameData(null);
    setScreen(ScreenType.TITLE);
  };

  const goToWaiting = (data: GameData) => {
    setGameData(data);
    setScreen(ScreenType.WAITING);
  };

  const goToBattleField = () => {
    setScreen(ScreenType.BATTLEFIELD);
  };

  const goToInterval = () => {
    setScreen(ScreenType.INTERVAL);
  };

  const renderScreen = () => {
    switch (screen) {
      case ScreenType.TITLE:
        return <Title goToMatching={goToMatching} />;
      case ScreenType.MATCHING:
        return (
          playerData && (
            <Matching
              playerData={playerData}
              goToWaiting={goToWaiting}
              goToTitle={goToTitle}
            />
          )
        );
      case ScreenType.WAITING:
        return (
          gameData && (
            <Waiting
              gameData={gameData}
              goToBattleField={goToBattleField}
              goToMatching={goToMatching}
              goToTitle={goToTitle}
            />
          )
        );
      case ScreenType.BATTLEFIELD:
        return (
          gameData && (
            <BattleField gameData={gameData} goToInterval={goToInterval} />
          )
        );
      case ScreenType.INTERVAL:
        return (
          gameData && (
            <Interval
              gameData={gameData}
              goToBattleField={goToBattleField}
              goToTitle={goToTitle}
            />
          )
        );
    }
  };

  return (
    <Stack className={styles.container}>
      {renderScreen()}
      <Box className={styles['volume-control-container']}>
        <VolumeControl />
      </Box>
    </Stack>
  );
}

export default Main;
