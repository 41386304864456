import React from 'react';
import './Hand.css';
import Tile from '../Tile/Tile';

const tileToImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_0.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_0.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_0.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_0.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_0.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_0.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_0.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_0.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_0.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_0.gif',
  '5j': 'assets/images/tile/jihai/p_no_0.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_0.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_0.gif',
};

type HandProps = {
  tiles: string[];
  immediatelyAfterDraw: boolean;
  onTileClick?: (tile: string) => void; // tile の文字列を渡すための handler
};

const Hand: React.FC<HandProps> = ({
  tiles,
  immediatelyAfterDraw,
  onTileClick,
}) => {
  return (
    <div className="hand-scroller">
      <div className="hand">
        {tiles.map((tile, index) => (
          <Tile
            key={index}
            tileImage={tileToImagePath[tile]}
            needHoverAnim={true}
            onTileClick={onTileClick ? () => onTileClick(tile) : undefined}
            immediatelyAfterDraw={
              immediatelyAfterDraw && index === tiles.length - 1
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Hand;
