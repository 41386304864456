import React from 'react';
import './Tile.css';

type TileProps = {
  /** タイル画像リソースURL */
  tileImage: string;
  /** 横向きに設置するか (大抵Meld(鳴き牌)の表示に用いる) */
  horizontal?: boolean;
  /** ホバーアニメーションを必要とするか */
  needHoverAnim?: boolean;
  /** 山から引いた直後の牌か */
  immediatelyAfterDraw?: boolean;
  /**  牌クリックコールバック */
  onTileClick?: () => void;
} & React.CSSProperties;

const Tile: React.FC<TileProps> = ({
  tileImage,
  horizontal,
  needHoverAnim = false,
  immediatelyAfterDraw = false,
  onTileClick,
  ...styles
}) => {
  return (
    <img
      style={styles}
      src={tileImage}
      alt="tile"
      className={`tile ${horizontal ? 'tile-horizontal' : ''} ${
        needHoverAnim ? 'tile-hover-anim' : ''
      } ${immediatelyAfterDraw ? 'tile-immediately-after-draw' : ''}`}
      onClick={onTileClick}
    />
  );
};

export default Tile;
