import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

type RuleDialogProps = {
  open: boolean;
  handleClose: () => void;
};

const RuleDialog: React.FC<RuleDialogProps> = ({ open, handleClose }) => {
  const [markdownData, setMarkdownData] = useState('');

  useEffect(() => {
    // .mdファイルの読み込み
    fetch('assets/rule/rule.md')
      .then(response => response.text())
      .then(data => setMarkdownData(data));
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>麻雀ルール</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <ReactMarkdown>{markdownData}</ReactMarkdown>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleDialog;
