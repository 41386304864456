import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AxiosResponse } from 'axios';
import ActionCable from 'actioncable';

import './Interval.css';

import { Card, CardContent, Typography, ThemeProvider } from '@mui/material';
import { StyledContainer } from '../../components/Styled/Styles';

import Hand from '../../components/Hand/Hand';
import Meld from '../../components/Meld/Meld';
import ActionButton from '../../components/ActionButton/ActionButton';

import { GameData } from '../../entities/GameData';
import { Theme } from '../../components/Theme/ColorTheme';
import { HttpMethod } from '../../entities/RequestParams';
import { webSocketUrl, requestWithRetry } from '../../utils/ApiUtils';
import { HoraData } from '../../entities/HoraData';

function Interval({
  gameData,
  goToBattleField,
  goToTitle,
}: {
  gameData: GameData;
  goToBattleField: () => void;
  goToTitle: () => void;
}) {
  const gameId = gameData.id;
  const playerId = gameData.player.id;

  const [gameStatus, setGameStatus] = useState<string | undefined>();
  const [horaData, setHoraData] = useState<HoraData | null>(null); // 和了情報
  const [tileList, setTileList] = useState<string[]>([]); // 自分の手牌
  const [meld, setMeld] = useState<string[][]>([]); // 自分の鳴いた牌

  useEffect(() => {
    // 対局ステータスの取得
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.GET,
      path: `/games/${gameId}`,
      onError: function (error: unknown) {
        console.error(`対局情報の取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setGameStatus(response.data['status']);
      },
    });

    // 和了情報の取得
    requestWithRetry({
      maxRetryCount: 3,
      method: HttpMethod.GET,
      path: `/latest_hora/${gameId}`,
      onError: function (error: unknown) {
        console.error(`和了情報の取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setHoraData(response.data);
      },
    });

    const cable = ActionCable.createConsumer(webSocketUrl);

    const subscription = cable.subscriptions.create(
      { channel: 'GameStatusChannel', game_id: gameId },
      {
        received: async (data) => {
          setGameStatus(data.status);
          if (
            data.status === 'matching_complete' ||
            data.status === 'tile_selection'
          ) {
            goToBattleField();
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // 手牌の取得
  useEffect(() => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.GET,
      path: `/hands/${gameId}/${horaData?.player_id}`,
      onError: function (error: unknown) {
        console.error(`手牌取得中にエラーが発生しました:${error}`);
      },
      onSuccess: function (response: AxiosResponse) {
        setTileList(response.data.hand);
        setMeld(response.data.meld);
      },
    });
  }, [horaData]);

  const handleNextClick = () => {
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.POST,
      path: `/games/${gameId}/${playerId}/goto_next_game`,
      onError: function (error: unknown) {
        console.error(`次対局遷移中にエラーが発生しました:${error}`);
      },
    });
  };

  const handleTitleClick = () => {
    goToTitle();
  };

  const isRyukyoku = horaData && horaData.point_name === '流局';
  const winner = gameStatus === 'end_of_game' ? horaData?.player_name : null;

  return (
    <ThemeProvider theme={Theme}>
      <StyledContainer color="default">
        {winner && <Typography variant="h5">勝者: {winner}</Typography>}
        {horaData ? (
          isRyukyoku ? (
            <Typography variant="h5">流局</Typography>
          ) : (
            <div>
              <Card>
                <CardContent>
                  <Typography variant="h5">
                    和了したプレイヤー: {horaData.player_name}
                  </Typography>
                  <Typography variant="body1">
                    {horaData.yaku_list.join(', ')}
                  </Typography>
                  <Typography variant="body1">
                    {horaData.point_name} {horaData.point}点
                  </Typography>
                </CardContent>
              </Card>
              <div className="interval-hand-area">
                <div className="interval-hand">
                  <Hand tiles={tileList} immediatelyAfterDraw />
                </div>
                <Meld meld={meld} />
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {gameStatus === 'interval' ? (
          <ActionButton
            isVisible={true}
            onClick={handleNextClick}
            label="次へ進む"
          />
        ) : (
          <ActionButton
            isVisible={true}
            onClick={handleTitleClick}
            label="対局終了"
          />
        )}
      </StyledContainer>
    </ThemeProvider>
  );
}

export default Interval;
