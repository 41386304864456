import React, { useState, useEffect, useRef } from 'react';
import { GameData } from '../../entities/GameData';
import { PlayerData } from '../../entities/PlayerData';

import { AxiosResponse } from 'axios';
import { HttpMethod } from '../../entities/RequestParams';
import { requestWithRetry } from '../../utils/ApiUtils';

import { Typography, Stack, ThemeProvider } from '@mui/material';
import { StyledContainer, StyledButton } from '../../components/Styled/Styles';

import RuleDialog from '../../components/RuleDialog/RuleDialog';
import ErrorDialog from '../../components/ErrorDialog/ErrorDialog';
import InfoDialog from '../../components/InfoDialog/InfoDialog';
import { Theme } from '../../components/Theme/ColorTheme';

function Waiting({
  gameData,
  goToBattleField,
  goToMatching,
  goToTitle,
}: {
  gameData: GameData;
  goToBattleField: () => void;
  goToMatching: (data: PlayerData) => void;
  goToTitle: () => void;
}) {
  const intervalSec = 1; // ゲーム状態を取得するインターバル
  const timeoutSec = 60; // 待機時間のタイムアウト値

  const [open, setOpen] = useState(false); // ルール表示のためのステート
  const [showDeleteError, setShowDeleteError] = useState(false); // ゲーム削除エラー表示のためのステート
  const [showMatchingError, setShowMatchingError] = useState(false); // マッチングエラー表示のためのステート
  const [showNotMatchingMessage, setShowNotMatchingMessage] = useState(false); // マッチングしなかった時のメッセージ表示のためのステート

  const ensureGameStatusInterval = useRef<ReturnType<typeof setInterval>>();

  const handleBack = async () => {
    if (ensureGameStatusInterval !== null) {
      clearInterval(ensureGameStatusInterval.current);
    }
    requestWithRetry({
      maxRetryCount: 0,
      method: HttpMethod.DELETE,
      path: `/games/${gameData.id}`,
      onError: function (error: unknown, retryCount: number) {
        setShowDeleteError(true);
      },
      onSuccess: function (_: AxiosResponse) {
        goToMatching(gameData.player);
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteErrorClose = () => {
    setShowDeleteError(false);
    goToTitle();
  };

  const handleMatchingErrorClose = () => {
    setShowMatchingError(false);
    goToMatching(gameData.player);
  };

  const handleNotMatchingMessageClose = () => {
    setShowNotMatchingMessage(false);
    handleBack();
  };

  // 一定時間おきにgameの状態を取得する
  useEffect(() => {
    // マッチングが成立したら対局画面へ遷移
    async function ensureGameStatus() {
      requestWithRetry({
        maxRetryCount: 0,
        method: HttpMethod.GET,
        path: `/games/${gameData.id}`,
        onError: function (error: unknown, retryCount: number) {
          setShowMatchingError(true);
        },
        onSuccess: function (response: AxiosResponse) {
          if (response.data['status'] !== 'waiting_for_matching') {
            goToBattleField();
          }
        },
      });
    }

    ensureGameStatus();
    ensureGameStatusInterval.current = setInterval(() => {
      ensureGameStatus();
    }, intervalSec * 1000);
    return () => clearInterval(ensureGameStatusInterval.current);
  });

  // 一定時間でタイムアウトする
  useEffect(() => {
    const timer = setTimeout(() => {
      clearInterval(ensureGameStatusInterval.current);
      setShowNotMatchingMessage(true);
    }, timeoutSec * 1000);
    return () => clearTimeout(timer);
  });

  return (
    <ThemeProvider theme={Theme}>
      <StyledContainer>
        <Typography variant="body1" gutterBottom>
          マッチング待機中：現在の合言葉は「{gameData.password}」<br />
          （同じ合言葉を入力した人とマッチングします）
        </Typography>
        <Stack direction="row">
          <StyledButton variant="contained" onClick={handleBack}>
            戻る
          </StyledButton>
          <StyledButton variant="contained" onClick={handleOpen}>
            ルール
          </StyledButton>
        </Stack>
        <RuleDialog open={open} handleClose={handleClose} />
        <ErrorDialog
          open={showDeleteError}
          handleClose={handleDeleteErrorClose}
          message="エラーが発生しました。最初からやり直してください。"
        />
        <ErrorDialog
          open={showMatchingError}
          handleClose={handleMatchingErrorClose}
          message="マッチング時にエラーが発生しました。"
        />
        <InfoDialog
          open={showNotMatchingMessage}
          handleClose={handleNotMatchingMessageClose}
          message="マッチングしませんでした。"
        />
      </StyledContainer>
    </ThemeProvider>
  );
}

export default Waiting;
