import React from 'react';
import './FuritenLabel.css';

type FuritenLabelProps = {
  isVisible: boolean;
};

const FuritenLabel: React.FC<FuritenLabelProps> = ({ isVisible }) => {
  return isVisible ? <span className="label-furiten">フリテン</span> : null;
};

export default FuritenLabel;
