import './Discard.css';
import React from 'react';
import Tile from '../Tile/Tile';

const yourTileToImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_1.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_1.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_1.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_1.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_1.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_1.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_1.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_1.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_1.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_1.gif',
  '5j': 'assets/images/tile/jihai/p_no_1.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_1.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_1.gif',
};

const opponentTileToImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_2.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_2.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_2.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_2.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_2.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_2.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_2.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_2.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_2.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_2.gif',
  '5j': 'assets/images/tile/jihai/p_no_2.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_2.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_2.gif',
};

type DiscardProps = {
  tiles: string[];
  is_your_discard: boolean;
};

const Discard: React.FC<DiscardProps> = ({ tiles, is_your_discard }) => {
  return (
    <div className={`discard ${is_your_discard ? '' : 'opponent-discard'}`}>
      {tiles.map((tile, index) => (
        <Tile
          key={index}
          tileImage={
            is_your_discard
              ? yourTileToImagePath[tile]
              : opponentTileToImagePath[tile]
          }
          rotate={is_your_discard ? undefined : '180deg'}
        />
      ))}
    </div>
  );
};

export default Discard;
