import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import './ReloadButton.css';

interface ReloadButtonProps {
  onClick: () => void;
}

const ReloadButton: React.FC<ReloadButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title="画面が固まった際に押すと再読み込みされます">
      <div className="reload-button">
        <IconButton color="primary" onClick={onClick}>
          <RefreshIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default ReloadButton;
