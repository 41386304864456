import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: '#006400', // 背景は深い緑
          color: '#fff', // 文字色は白
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff', // ボタンは白色
          color: '#006400', // ボタンの文字色は緑
        },
      },
    },
  },
});
