import * as Tone from 'tone';

export const drawSound = (): void => {
  const synth: Tone.Synth = new Tone.Synth({
    oscillator: {
      type: 'sine',
    },
    envelope: {
      attack: 0.01,
      decay: 0.1,
      sustain: 0.1,
      release: 0.1,
    },
  }).toDestination();

  synth.triggerAttackRelease('C4', '32n');
};
