import './OpponentHand.css';
import React from 'react';

type Props = {
  opponentHandNum: number;
};

const OpponentHand: React.FC<Props> = ({ opponentHandNum }) => {
  return (
    <div className="opponent-hand">
      {Array(opponentHandNum)
        .fill(0)
        .map((_, index) => (
          <img
            key={index}
            src="assets/images/tile/back/p_bk_0.gif"
            alt="tile-back"
          />
        ))}
    </div>
  );
};

export default OpponentHand;
