import React, { useState, useEffect } from 'react';
import * as Tone from 'tone';
import { Slider, Box, IconButton } from '@mui/material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import styles from './VolumeControl.module.css';

const VolumeControl = () => {
  const [volume, setVolume] = useState(0);
  const minVolume = -60;
  const maxVolume = 0;
  const volumeBarOffset = 3;

  useEffect(() => {
    if (volume <= minVolume + volumeBarOffset) {
      Tone.getDestination().mute = true;
    } else {
      Tone.getDestination().mute = false;
      Tone.getDestination().volume.rampTo(volume, 0.1);
    }
  }, [volume]);

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setVolume(newValue);
    }
  };

  return (
    <Box className={styles.container}>
      <IconButton aria-label="volume up">
        <VolumeUp />
      </IconButton>
      <Slider
        orientation="vertical"
        min={minVolume}
        max={maxVolume}
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="vertical-slider"
        className={styles.slider}
      />
      <IconButton aria-label="volume off">
        <VolumeOff />
      </IconButton>
    </Box>
  );
};

export default VolumeControl;
