import axios, { AxiosResponse } from 'axios';
import { HttpMethod, RequestParams } from '../entities/RequestParams';

const isLocalHost = window.location.hostname === 'localhost';
export const webSocketUrl = isLocalHost
  ? 'ws://localhost:3001/cable'
  : 'wss://api.nube-games.net/cable';
const baseUrl = isLocalHost
  ? 'http://localhost:3001'
  : 'https://api.nube-games.net';

export const requestWithRetry = async ({
  retryCount = 0,
  waitResponse = true,
  ...params
}: RequestParams): Promise<AxiosResponse | void> => {
  const makeRequest = async (): Promise<AxiosResponse | void> => {
    try {
      let response: AxiosResponse;
      switch (params.method) {
        case HttpMethod.POST:
          response = await axios({
            method: params.method,
            url: baseUrl + params.path,
            data: params.data,
          });
          break;
        default:
          response = await axios({
            method: params.method,
            url: baseUrl + params.path,
          });
      }
      if (typeof params.onSuccess !== 'undefined') {
        params.onSuccess(response);
      }
      return response;
    } catch (error) {
      params.onError?.(error, retryCount);
      if (retryCount < params.maxRetryCount) {
        return await requestWithRetry({
          ...params,
          retryCount: retryCount + 1,
          waitResponse,
        });
      }
      throw error;
    }
  };

  if (waitResponse) {
    return await makeRequest();
  } else {
    makeRequest();
  }
};
