import './CenterPanel.css';
import React from 'react';
import { Box, Typography, Paper, Chip } from '@mui/material';
import { Image } from '@mui/icons-material';

type Props = {
  yourPoint: number;
  opponentPoint: number;
  remainingDeck: number;
  isParent: boolean;
  playerId: number;
  turnPlayerId: number | undefined;
};

const CenterPanel: React.FC<Props> = ({
  yourPoint,
  opponentPoint,
  remainingDeck,
  isParent,
  playerId,
  turnPlayerId,
}) => {
  return (
    <Box className="center-panel">
      <Paper elevation={3} sx={{ overflow: 'hidden', width: '100%' }}>
        <CenterPanelIndicator blinking={playerId !== turnPlayerId} />
        <Box className="center-panel-flexible">
          <PointShower
            role={isParent ? '子' : '親'}
            point={opponentPoint}
            isOpponent={true}
          />
          <Box display="flex" gap="12px" justifyContent="space-around">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                gap: '4px',
                justifyContent: 'center',
              }}>
              <div>
                <img
                  width="15px"
                  src="assets/images/tile/back/p_bk_0.gif"
                  alt="山"
                />
              </div>
              {'x'}
              {remainingDeck}
            </Box>
            <Box
              sx={{
                fontWeight: 'bold',
                background: '#ffa760',
                border: 'solid 2px #ff802b',
                borderRadius: '10px',
                padding: '2px 4px',
                fontSize: '14px',
              }}>
              東
            </Box>
          </Box>
          <PointShower
            role={isParent ? '親' : '子'}
            point={yourPoint}
            isOpponent={false}
          />
        </Box>
        <CenterPanelIndicator blinking={playerId === turnPlayerId} />
      </Paper>
    </Box>
  );
};

const CenterPanelIndicator: React.FC<{ blinking: boolean }> = ({
  blinking,
}) => {
  return (
    <Box
      className={blinking ? 'center-panel-indicator' : ''}
      sx={{
        height: '10px',
        width: '100%',
        zIndex: 1,
      }}
    />
  );
};

const PointShower: React.FC<{
  role: '親' | '子';
  point: number;
  isOpponent: boolean;
}> = ({ role, point, isOpponent }) => {
  return (
    <Box
      display="flex"
      alignItems={isOpponent ? 'flex-start' : 'flex-end'}
      gap="4px">
      <Typography fontSize={14} lineHeight="18px">
        {role}
      </Typography>
      <Typography fontSize={20} lineHeight="18px" fontWeight="bold">
        {point.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default CenterPanel;
