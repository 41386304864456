import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

type InfoDialogProps = {
  open: boolean;
  handleClose: () => void;
  message: string;
};

const InfoDialog: React.FC<InfoDialogProps> = ({
  open,
  handleClose,
  message,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
