import React from 'react';
import Tile from '../Tile/Tile';
import './OpponentMeld.css';

type OpponentMeldProps = {
  meld: string[][];
};

const tileToVerticalImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_2.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_2.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_2.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_2.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_2.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_2.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_2.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_2.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_2.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_2.gif',
  '5j': 'assets/images/tile/jihai/p_no_2.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_2.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_2.gif',
};

const tileToHorizontalImagePath: { [key: string]: string } = {
  '1m': 'assets/images/tile/manzu/p_ms1_3.gif',
  '9m': 'assets/images/tile/manzu/p_ms9_3.gif',
  '1p': 'assets/images/tile/pinzu/p_ps1_3.gif',
  '9p': 'assets/images/tile/pinzu/p_ps9_3.gif',
  '1s': 'assets/images/tile/sozu/p_ss1_3.gif',
  '9s': 'assets/images/tile/sozu/p_ss9_3.gif',
  '1j': 'assets/images/tile/jihai/p_ji_e_3.gif',
  '2j': 'assets/images/tile/jihai/p_ji_s_3.gif',
  '3j': 'assets/images/tile/jihai/p_ji_w_3.gif',
  '4j': 'assets/images/tile/jihai/p_ji_n_3.gif',
  '5j': 'assets/images/tile/jihai/p_no_3.gif',
  '6j': 'assets/images/tile/jihai/p_ji_h_3.gif',
  '7j': 'assets/images/tile/jihai/p_ji_c_3.gif',
};

const OpponentMeld: React.FC<OpponentMeldProps> = ({ meld }) => {
  if (meld.length === 0) return <></>;
  return (
    <>
      {meld.map((meld, index) => (
        <div key={index} className="opponent-meld-group">
          {meld[0] === 'pon' && (
            <>
              <Tile tileImage={tileToVerticalImagePath[meld[1]]} />
              <Tile
                tileImage={tileToHorizontalImagePath[meld[2]]}
                horizontal={true}
              />
              <Tile tileImage={tileToVerticalImagePath[meld[3]]} />
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default OpponentMeld;
