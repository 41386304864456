import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField } from '@mui/material';
import { StyledButton, SuccessMessage } from '../Styled/Styles';
import { HttpMethod } from '../../entities/RequestParams';
import { requestWithRetry } from '../../utils/ApiUtils';

interface FeedbackModalProps {
  open: boolean;
  handleClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, handleClose }) => {
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [nameError, setNameError] = useState('');
  const [feedbackError, setFeedbackError] = useState('');
  const [postFeedbackResult, setPostFeedbackResult] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateInput = () => {
    let valid = true;
    if (name.length < 1 || name.length > 20) {
      setNameError('名前は1〜20文字で入力してください');
      valid = false;
    } else {
      setNameError('');
    }
    if (comment.length < 1 || comment.length > 200) {
      setFeedbackError('本文は1〜200文字で入力してください');
      valid = false;
    } else {
      setFeedbackError('');
    }
    return valid;
  };

  const handleSubmit = async () => {
    if (validateInput()) {
      setIsSubmitting(true); // 送信中に設定
      try {
        await requestWithRetry({
          method: HttpMethod.POST,
          path: '/feedbacks',
          data: {
            name: name,
            comment: comment,
          },
          maxRetryCount: 0,
          onError: (error, retryCount) => {
            console.error(
              `Error sending feedback (retry ${retryCount}):`,
              error
            );
          },
          onSuccess: () => {
            setPostFeedbackResult('送信が完了しました。');
            setTimeout(() => {
              setPostFeedbackResult(''); // 一定時間後にメッセージを非表示
              setName(''); // フォームをリセット
              setComment(''); // フォームをリセット
              handleClose(); // モーダルを閉じる
              setIsSubmitting(false);
            }, 2000); // メッセージを表示する時間（ミリ秒）
          },
        });
      } catch (error) {
        setPostFeedbackResult('フィードバックの送信に失敗しました。');
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (!open) {
      setName(''); // モーダルが閉じられる時にフォームをリセット
      setComment(''); // モーダルが閉じられる時にフォームをリセット
      setNameError(''); // エラーメッセージをクリア
      setFeedbackError(''); // エラーメッセージをクリア
      setPostFeedbackResult(''); // 成功メッセージをクリア
      setIsSubmitting(false); // モーダルが閉じられた時に送信中の状態を解除
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
        <Typography variant="h6" component="h2" gutterBottom>
          フィードバック
        </Typography>
        <TextField
          label="名前(20文字以内)"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
          error={Boolean(nameError)}
          helperText={nameError}
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          label="本文(200文字以内)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={8}
          error={Boolean(feedbackError)}
          helperText={feedbackError}
          inputProps={{ maxLength: 200 }}
        />
        {postFeedbackResult && (
          <SuccessMessage variant="body2">{postFeedbackResult}</SuccessMessage>
        )}
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <StyledButton
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting} // 送信中の場合は無効化
          >
            送信
          </StyledButton>
          <StyledButton
            variant="outlined"
            onClick={handleClose}
            disabled={isSubmitting}>
            キャンセル
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
