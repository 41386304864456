import React, { useEffect, useState } from 'react';
import './App.css';
import Main from './pages/Main/Main';

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const minWidth = 800;
      const maxWidth = 1000;
      const newWidth = Math.max(minWidth, Math.min(window.innerWidth, maxWidth));
      setWindowWidth(newWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="app" style={{ width: windowWidth }}>
      <Main />
    </div>
  );
}

export default App;
