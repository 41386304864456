import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  backgroundColor: 'rgba(128, 128, 128, 0.9)', // 半透明の灰色
  color: '#FFFFFF', // ボタンの文字色は白
  position: 'relative',
  display: 'inline-block',
  '&:hover': {
    backgroundColor: 'rgba(64, 64, 64, 1.0)', // ホバー時に色をより濃くします
  },
});

interface ActionButtonProps {
  isVisible: boolean;
  onClick: () => void;
  label: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  isVisible,
  onClick,
  label,
}) => {
  if (!isVisible) return null;

  return (
    <div>
      <StyledButton onClick={onClick}>{label}</StyledButton>
    </div>
  );
};

export default ActionButton;
